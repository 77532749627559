/*****************************************************/
// Capa de Servicios para el modulo de Transportacion.

import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + store.getters.getToken
        }
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/transportations");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("response.data:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getData() {
        try {
            let response = await axios.get(this.#baseUrl + "/view/transportations");
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewList() {
        try {
            let response = await axios.get(this.#baseUrl + "/view-list/transportations");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/transportations/${id}`);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async addRecord(formData) {
        // console.log('Adding Record.', formData);
        try {
            let recordData = new FormData();
            recordData.append("id", formData.id);
            recordData.append("name", formData.name);
            recordData.append("description", formData.description);
            recordData.append("establishment_type", formData.establishment_type);
            recordData.append("email", formData.email);
            recordData.append("address", formData.address);
            recordData.append("phone", formData.phone);
            recordData.append("web_page", formData.web_page);
            recordData.append("map_lat", formData.map_lat);
            recordData.append("map_lng", formData.map_lng);
            recordData.append("facebook_profile", formData.facebook_profile);
            recordData.append("instagram_profile", formData.instagram_profile);
            recordData.append("youtube_video", formData.youtube_video);
            recordData.append("servicehours", formData.servicehours);
            // console.log('Record-Data: ', recordData);
            let response = await axios.post(this.#baseUrl + "/transportations", recordData);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    // Pushes posts to the server when called.
    async updateRecord(formData) {
        try {
            let recordData = new FormData();
            recordData.append("id", formData.id);
            recordData.append("name", formData.name);
            recordData.append("description", formData.description);
            recordData.append("establishment_type", formData.establishment_type);
            recordData.append("email", formData.email);
            recordData.append("address", formData.address);
            recordData.append("phone", formData.phone);
            recordData.append("web_page", formData.web_page);
            recordData.append("map_lat", formData.map_lat);
            recordData.append("map_lng", formData.map_lng);
            recordData.append("facebook_profile", formData.facebook_profile);
            recordData.append("instagram_profile", formData.instagram_profile);
            recordData.append("youtube_video", formData.youtube_video);
            recordData.append("servicehours", formData.servicehours);
            recordData.append('_method', 'put');
            // console.log('Record-Data: ', recordData);
            let response = await axios.post(`${this.#baseUrl}/transportations/${formData.id}`,recordData);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async deleteRecord(id) {
        try {
            // console.log('Deleting Record: %s ', id);
            let response = await axios.delete(`${this.#baseUrl}/transportations/${id}`);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async uploadImage(transportation_id, picture_id, imageFile) {
        if (imageFile) {
            try {
                let formData = new FormData();
                formData.append("transportation_id", transportation_id);
                formData.append("picture_id", picture_id);
                formData.append("file", imageFile);
                let response = await axios.post(this.#baseUrl + '/upload-image/transportations', formData);
                if (response.data.success) {
                    // console.log('Respuesta: ', response.data);
                    return response.data;
                } else {
                    return null;
                }
            } catch (error) {
                return this.handleError(error);
            }
        }
    }

    async deleteImage(transportation_id, picture_id) {
        if (picture_id) {
            try {
                let formData = new FormData();
                formData.append("transportation_id", transportation_id);
                formData.append("picture_id", picture_id);
                let response = await  axios.post(this.#baseUrl + '/delete-image/transportations', formData);
                if (response.data.success) {
                    // console.log('Respuesta: ', response.data);
                    return response.data;
                } else {
                    return null;
                }
            } catch (error) {
                return this.handleError(error);
            }
        }
    }

    async sendEmail(profile_id, email) {
        try {
            let response = await axios.get(`${this.#baseUrl}/send-email/transportations?id=${profile_id}&email=${email}`);
            // console.log('Respuesta: ', response);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            let result;
            result = this.handleError(error);
            return result;
        }
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            valueError = error.response.data.messaje;
        } else if (error.request) {
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
        }
        return valueError;
    }
}
